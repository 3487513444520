<template>
  <nav class="nav-list">
    <SlotLink v-if="data.url" :url="data.url" class="nav-list__link" show-chevron>
      {{ data.title }}
    </SlotLink>
    <ul class="nav-list__list">
      <AppNavListItem
        v-for="(item, index) in otherLinks"
        :key="index"
        :title="item.title"
        :url="item.url"
        :children="item.children"
        :level="level"
        :has-accordion="hasAccordion"
        :accordion-index="index"
        :accordion-active-index="accordionActiveIndex"
        :is-wide="item?.is_wide || false"
        @update-accordion-active-index="updateAccordionActiveIndex"
      />
      <AppNavListItem
        v-if="level3Links.length > 0"
        :children="level3Links"
        :level="level"
        :has-accordion="hasAccordion"
        :accordion-index="otherLinks.length + 1"
        :accordion-active-index="accordionActiveIndex"
        :is-wide="true"
        @update-accordion-active-index="updateAccordionActiveIndex"
      />
    </ul>
  </nav>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    level: {
        type: Number,
        default: 0,
    },
    hasAccordion: {
        type: Boolean,
        default: false,
    },
});

const level3Links = computed(() => (props.level === 3 && Array.isArray(props.data.children)
    ? props.data.children
        .filter((item) => item.children?.length === 0 || item.children === undefined)
    : []));

const otherLinks = computed(() => {
    if (props.level === 3) {
        return Array.isArray(props.data.children)
            ? props.data.children.filter((item) => item.children?.length > 0)
            : [];
    }
    return props.data.children;
});

/*
  Setup
*/
const flyoutStore = useFlyoutStore();

/*
Set Active Index for Accordion
*/
const accordionActiveIndex = ref(-1);

const updateAccordionActiveIndex = (index) => {
    accordionActiveIndex.value = index;
    flyoutStore.updateLevel2Index(index);
};

/*
  Find the first index of the array that has children
*/
const firstArrayIndex = computed(
    () => props.data?.children.findIndex((element) => element.children?.length > 0),
);

/*
  Set the accordionActiveIndex to the first index of the array that has children
*/
const level1Index = computed(() => flyoutStore.getLevel1Index);
accordionActiveIndex.value = firstArrayIndex.value;

/*
  Watch for changes in the level1Index and update the accordionActiveIndex
*/
watch(() => level1Index.value, () => {
    accordionActiveIndex.value = firstArrayIndex.value;
    flyoutStore.updateLevel2Index(firstArrayIndex.value);
});

/*
  Watch for changes in the firstArrayIndex and update the accordionActiveIndex
*/
watch(() => firstArrayIndex.value, () => {
    accordionActiveIndex.value = firstArrayIndex.value;
    flyoutStore.updateLevel2Index(firstArrayIndex.value);
});

</script>

<style lang="scss">
.nav-list__link {
    @include typo-font('regular');
    @include typo-size('navLevel2');

    margin-bottom: 31px;
    color: $C_PRIMARY;
}
</style>
